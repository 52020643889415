import React, { useRef, useEffect, useState } from 'react';

function ImageEraser() {
  const canvasRef = useRef(null);
  const [erasedArea, setErasedArea] = useState(0); // Track the erased area

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = 'Rectangle 118.png'; // Replace with your image path
    image.onload = () => {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const totalArea = canvas.width * canvas.height;
    const eraserRadius = 100;
    const eraserArea = Math.PI * eraserRadius * eraserRadius;

    const erase = (e) => {
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      ctx.beginPath();
      ctx.arc(x, y, eraserRadius, 0, 2 * Math.PI); // Circle path
      ctx.clip();
      ctx.clearRect(x - eraserRadius, y - eraserRadius, eraserRadius * 2, eraserRadius * 2); // Clear within the circle
      ctx.restore();

      // Update the erased area state
      setErasedArea((prevArea) => {
        const updatedArea = prevArea + eraserArea;
        // Check if the erased area is more than 20% of the total canvas area
        if (updatedArea / totalArea > 1.7) {
          // Erase the entire canvas
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          return totalArea; // Update the state to reflect the entire canvas being erased
        }
        return updatedArea;
      });

      // Prepare for next clipping
      ctx.save();
      ctx.beginPath();
      ctx.rect(0, 0, canvas.width, canvas.height);
      ctx.clip();
    };

    ctx.save(); // Initial save to setup for clipping

    // Update: Remove isErasing state checks and always erase on mouse move
    canvas.addEventListener('mousemove', erase);

    return () => {
      canvas.removeEventListener('mousemove', erase);
    };
  }, []); // Removed isErasing dependency

  return (
    <div>
      <canvas ref={canvasRef} width={640} height={3600}
              style={{
                cursor: 'crosshair',
                position: 'absolute',
                top: 0,
                left: 0,
                marginTop: 100,
                marginRight: 0,
                marginBottom: 70,
                marginLeft: 210,
              }}></canvas>
    </div>
  );
}

export default ImageEraser;
