import React, { useEffect } from 'react';
import $ from 'jquery';
import 'jquery.ripples';


function RippleEffect() {
  useEffect(() => {
    // Initialize the plugin on the desired element
    $('.container').ripples({
      resolution: 2048,
      dropRadius: 80,
      perturbance: 0.04,
    });

    // Destroy the plugin instance when component unmounts
    return () => {
      $('.container').ripples('destroy');
    };
  }, []);

  return (
    <div className="container">
      {/* Your content */}

    </div>
  );
}

export default RippleEffect;
