import React, {useState, useRef} from 'react';
import ScratchCard from 'react-scratchcard-v2';
import './App.css'; // Assuming you have some styles defined here
import RippleEffect from "./RippleEffect";
import ImageEraser from "./ImageEraser";
import CustomCursor from "./CustomCursor";

// import * as IMG from './chi.jpg'; // Import the image for the scratch card


function App() {
  const [isPlaying, setIsPlaying] = useState(false);

  const toggleSound = () => {
    setIsPlaying(!isPlaying);
    const audio = document.getElementById('backgroundAudio');
    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
    }
  };

  const ref = useRef();

  const handleComplete = () => {
    console.log('Scratch completed!');
    // You can add logic here for what happens when the scratch is complete
  };

  const handleReset = () => {
    ref.current.reset();
  };


  return (
    <div className="container">
      <div className="left">
        {/*<div className="scratch-card">*/}
        {/*</div>*/}
        <img
          className="token"
          src="/chinew.jpg"
          alt="twi"
          // style={{width: '44px', height: '44px',}}
        />
        <div style={{height: "100vh"}}/>
      </div>
      <div className="right">
        <img className="cat" src="/chi.jpg" alt="Cat"/>

        <img
          className="volume"
          src={isPlaying ? "/on.png" : "/off.png"}
          alt={isPlaying ? "Sound On" : "Sound Off"}
          onClick={toggleSound}
        />


        <a href="https://twitter.com/Chis_sweethome" target="_blank">
          <img
            // className="twi"
            className="far"
            src="/twi.png"
            alt="twi"
            // style={{width: '44px', height: '44px',}}
          />
        </a>
        {/*<a href="https://warpcast.com/chissweethome" target="_blank">*/}
        {/*  <img*/}
        {/*    className="far"*/}
        {/*    src="/far.png"*/}
        {/*    alt="far"*/}
        {/*    // style={{width: '44px', height: '44px', borderRadius: '10px', marginLeft: '4px'}}*/}
        {/*  />*/}
        {/*</a>*/}
        <a href="https://dexscreener.com/ethereum/0x502c85d10a4bE6aC4B53D6D648D9673379442284" target="_blank">
          <img
            className="far"
            src="/newlogo2.webp"
            alt="far"
            style={{left:'24%', marginRight:'15px'}}
            // style={{width: '44px', height: '44px', borderRadius: '10px', marginLeft: '4px'}}
          />
        </a>
        <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x502c85d10a4be6ac4b53d6d648d9673379442284?t=1726365501862" target="_blank">
          <img
            className="far"
            src="/newlogo3.webp"
            alt="far"
            style={{left: '18%', marginRight:'30px'}}

            // style={{width: '44px', height: '44px', borderRadius: '10px', marginLeft: '4px'}}
          />
        </a>


        <audio id="backgroundAudio" loop autoPlay>
          <source src="/music.mp3" type="audio/mpeg"/>
          Your browser does not support the audio element.
        </audio>
        <RippleEffect/>

      </div>

      {/*<ImageEraser/>*/}
      <CustomCursor />

    </div>

  );
}

export default App;


// <p>
//   Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//   <br/>
//   Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//   <br/>
//
//   <br/>
//   Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris ni<br/>
//   si ut aliquip ex ea commodo consequat. Duis aute irure dolor in repre<br/>
//   henderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.<br/>
//   Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia <br/>
//   deserunt mollit anim id est laborum.
//   <br/>
//   <br/>
//   Curabitur pretium tincidunt lacus. Nulla gravida orci a odio.<br/>
//   Nullam varius, turpis et commodo pharetra, est eros bibendum elit, <br/>
//   nec luctus magna felis sollicitudin mauris. Integer in mauris eu nibh <br/>
//   euismod gravida. Duis ac tellus et risus vulputate vehicula. Donec lobortis <br/>
//   risus a elit. Etiam tempor. Ut ullamcorper, ligula eu tempor congue, eros est <br/>
//   euismod turpis, id tincidunt sapien risus a quam. Maecenas fermentum consequat <br/>
//   mi. Donec fermentum. Pellentesque malesuada nulla a mi. Duis sapien sem, alique<br/>
//   t nec, commodo eget, consequat quis, neque. Aliquam faucibus, elit ut dictum al<br/>
//   iquet, felis nisl adipiscing sapien, sed malesuada diam lacus eget erat. Cras m<br/>
//   ollis scelerisque nunc. Nullam arcu. Aliquam consequat. Curabitur augue lorem, <br/>
//   dapibus quis, laoreet et, pretium ac, nisi. Aenean magna nisl, mollis quis, mole<br/>
//   stie eu, feugiat in, orci. In hac habitasse platea dictumst.<br/>
//   Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//   <br/>
//   Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//   <br/>
//   <br/>
//   Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris ni<br/>
//   si ut aliquip ex ea commodo consequat. Duis aute irure dolor in repre<br/>
//   henderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.<br/>
//   Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia <br/>
//   deserunt mollit anim id est laborum.
//   <br/>
//   <br/>
//   Curabitur pretium tincidunt lacus. Nulla gravida orci a odio.<br/>
//   Nullam varius, turpis et commodo pharetra, est eros bibendum elit, <br/>
//   nec luctus magna felis sollicitudin mauris. Integer in mauris eu nibh <br/>
//   euismod gravida. Duis ac tellus et risus vulputate vehicula. Donec lobortis <br/>
//   risus a elit. Etiam tempor. Ut ullamcorper, ligula eu tempor congue, eros est <br/>
//   euismod turpis, id tincidunt sapien risus a quam. Maecenas fermentum consequat <br/>
//   mi. Donec fermentum. Pellentesque malesuada nulla a mi. Duis sapien sem, alique<br/>
//   t nec, commodo eget, consequat quis, neque. Aliquam faucibus, elit ut dictum al<br/>
//   iquet, felis nisl adipiscing sapien, sed malesuada diam lacus eget erat. Cras m<br/>
//   ollis scelerisque nunc. Nullam arcu. Aliquam consequat. Curabitur augue lorem, <br/>
//   dapibus quis, laoreet et, pretium ac, nisi. Aenean magna nisl, mollis quis, mole<br/>
//   stie eu, feugiat in, orci. In hac habitasse platea dictumst.<br/>
//   Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//   <br/>
//   Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//   <br/>
//   <br/>
//   Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris ni<br/>
//   si ut aliquip ex ea commodo consequat. Duis aute irure dolor in repre<br/>
//   henderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.<br/>
//   Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia <br/>
//   deserunt mollit anim id est laborum.
//   <br/>
//   <br/>
//   Curabitur pretium tincidunt lacus. Nulla gravida orci a odio.<br/>
//   Nullam varius, turpis et commodo pharetra, est eros bibendum elit, <br/>
//   nec luctus magna felis sollicitudin mauris. Integer in mauris eu nibh <br/>
//   euismod gravida. Duis ac tellus et risus vulputate vehicula. Donec lobortis <br/>
//   risus a elit. Etiam tempor. Ut ullamcorper, ligula eu tempor congue, eros est <br/>
//   euismod turpis, id tincidunt sapien risus a quam. Maecenas fermentum consequat <br/>
//   mi. Donec fermentum. Pellentesque malesuada nulla a mi. Duis sapien sem, alique<br/>
//   t nec, commodo eget, consequat quis, neque. Aliquam faucibus, elit ut dictum al<br/>
//   iquet, felis nisl adipiscing sapien, sed malesuada diam lacus eget erat. Cras m<br/>
//   ollis scelerisque nunc. Nullam arcu. Aliquam consequat. Curabitur augue lorem, <br/>
//   dapibus quis, laoreet et, pretium ac, nisi. Aenean magna nisl, mollis quis, mole<br/>
//   stie eu, feugiat in, orci. In hac habitasse platea dictumst.<br/>
//   Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//   <br/>
//   Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
//   <br/>
//   <br/>
//   Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris ni<br/>
//   si ut aliquip ex ea commodo consequat. Duis aute irure dolor in repre<br/>
//   henderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.<br/>
//   Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia <br/>
//   deserunt mollit anim id est laborum.
//   <br/>
//   <br/>
//   Curabitur pretium tincidunt lacus. Nulla gravida orci a odio.<br/>
//   Nullam varius, turpis et commodo pharetra, est eros bibendum elit, <br/>
//   nec luctus magna felis sollicitudin mauris. Integer in mauris eu nibh <br/>
//   euismod gravida. Duis ac tellus et risus vulputate vehicula. Donec lobortis <br/>
//   risus a elit. Etiam tempor. Ut ullamcorper, ligula eu tempor congue, eros est <br/>
//   euismod turpis, id tincidunt sapien risus a quam. Maecenas fermentum consequat <br/>
//   mi. Donec fermentum. Pellentesque malesuada nulla a mi. Duis sapien sem, alique<br/>
//   t nec, commodo eget, consequat quis, neque. Aliquam faucibus, elit ut dictum al<br/>
//   iquet, felis nisl adipiscing sapien, sed malesuada diam lacus eget erat. Cras m<br/>
//   ollis scelerisque nunc. Nullam arcu. Aliquam consequat. Curabitur augue lorem, <br/>
//   dapibus quis, laoreet et, pretium ac, nisi. Aenean magna nisl, mollis quis, mole<br/>
//   stie eu, feugiat in, orci. In hac habitasse platea dictumst.<br/>
//
// </p>
